import Vue from 'vue'
import App from './App.vue'
import "regenerator-runtime"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueHtml2Canvas from 'vue-html2canvas'

import 'swiper/dist/css/swiper.css'
import './styles/main.scss'

Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
Vue.use(VueHtml2Canvas)

new Vue({
  render: h => h(App),
}).$mount('#app')
